import { EsAggregationsDefinition } from '@bisondesk/core-sdk/lib/types/definitions';
import { FieldTypes } from '@bisondesk/core-sdk/lib/types/fields';
import {
  AggregationResult,
  ComplexFilter,
  ConditionType,
  ElasticsearchOperator,
  Filter,
  TermAggregation,
} from '@bisondesk/core-sdk/lib/types/search';
import { ReferenceData } from '@bisondesk/core-sdk/lib/types/utils';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { SxProps } from '@mui/system';
import { get, uniqBy } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { colors, monospaceFontFamily } from '../../layout/styles';
import { useFilters } from './FiltersProvider';

const MIN_COLLAPSIBLE_LENGTH = 5;

type Props = {
  condition?: ComplexFilter<ElasticsearchOperator>;
  field: TermAggregation;
  definition: EsAggregationsDefinition;
  meta: ReferenceData;
  values?: AggregationResult[];
};

const aggOption: SxProps = {
  fontSize: {
    xs: '1rem',
    sm: '0.8125rem',
  },
  color: colors.greys.A700,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const AggTermsBlock = ({ values = [], condition, field, meta, definition }: Props) => {
  const { upsertCondition } = useFilters();

  const selected = useMemo(
    () =>
      condition == null
        ? new Set<string>()
        : new Set<string>(condition.filters.map((f) => (f as Filter).data)),
    [condition]
  );

  const onChange = useCallback(
    (value: string, checked: boolean): void => {
      const existingFilters = [...selected].map((v) => ({
        data: v,
        fieldId: field.fieldId,
        operator: ElasticsearchOperator.is,
      }));

      const newFilters = checked
        ? [
            ...existingFilters,
            {
              data: value,
              fieldId: field.fieldId,
              operator: ElasticsearchOperator.is,
            },
          ]
        : existingFilters.filter((f) => f.data !== value);

      upsertCondition(
        field.fieldId,
        newFilters.length > 0
          ? {
              condition: ConditionType.or,
              filters: newFilters,
            }
          : undefined
      );
    },
    [field, upsertCondition, selected]
  );

  const visibleOptions = useMemo(() => {
    // safeValues is necessary when the fullText search makes the server result come without the already selected terms
    const safeValues: AggregationResult[] = uniqBy(
      [...values, ...[...selected].map((value) => ({ value, count: 0 }))],
      (v) => v.value
    );

    const newOptions: AggregationResult[] = [];
    for (const op of safeValues) {
      const isSelected = selected.has(op.value);
      if (newOptions.length < MIN_COLLAPSIBLE_LENGTH || isSelected) {
        newOptions.push(op);
      }
    }
    return newOptions;
  }, [values, selected]);

  return (
    <AggTerms
      definition={definition}
      meta={meta}
      visibleOptions={visibleOptions}
      fieldId={field.fieldId}
      onChange={onChange}
      selected={selected}
    />
  );
};

type AggTermsProps = {
  fieldId: string;
  definition: EsAggregationsDefinition;
  meta: ReferenceData;
  onChange: (v: string, c: boolean) => void;
  selected: Set<string>;
  visibleOptions: AggregationResult[];
};

const AggTerms = ({
  fieldId,
  visibleOptions,
  onChange,
  selected,
  meta,
  definition,
}: AggTermsProps) => {

  const getValueTitle = useCallback(
    (value: string) => {
      const field = get(definition, fieldId);

      if (field == null || field.type !== FieldTypes.picklist) {
        return value;
      }

      const { title }: { title: string; color?: string } = get(
        meta.picklistTitles,
        [field.meta.picklistId, value],
        { title: value }
      );

      return title ?? value;
    },
    [definition, fieldId, meta.picklistTitles]
  );

  return visibleOptions.length === 0 ? (
    <Box
      sx={{
        color: colors.greys.A500,
        fontSize: '12px',
        padding: '0 8px',
      }}
    >
      No values
    </Box>
  ) : (
    <Box
      sx={{
        marginTop: '12px',
      }}
    >
      {visibleOptions.map((op) => (
        <FormControlLabel
          key={`agg-${fieldId}_${op.value}`}
          sx={{
            '& .MuiFormControlLabel-root': {
              display: 'flex',
              alignItems: 'center',
              marginRight: 0,
              marginTop: '-10px',
              sm: {
                padding: 0,
              },
            },
            '& .MuiFormControlLabel-label': {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            },
          }}
          control={
            <Checkbox
              checked={selected != null && selected.has(op.value)}
              onChange={(_, checked) => onChange(op.value, checked)}
              value={op.value}
              color="primary"
            />
          }
          label={
            <>
              <Box sx={aggOption}>{getValueTitle(op.value)}</Box>
              <Box
                sx={{ fontSize: '12px', color: colors.greys.A500, fontFamily: monospaceFontFamily }}
              >
                {op.count}
              </Box>
            </>
          }
        />
      ))}
    </Box>
  );
};
