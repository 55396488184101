import {
  Aggregation,
  AggregationResult,
  AggregationType,
  ComplexFilter,
  ConditionType,
  ElasticsearchOperator,
  Filter,
  RangeAggregation,
} from '@bisondesk/core-sdk/lib/types/search';
import { Box, Slider } from '@mui/material';
import { first, last } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFilters } from './FiltersProvider';

type Props = {
  values?: AggregationResult[];
  field: RangeAggregation;
  condition?: ComplexFilter<ElasticsearchOperator>;
};

const getInitialValues = (field: Aggregation, condition?: ComplexFilter<ElasticsearchOperator>) => {
  const fieldRanges = field.type === AggregationType.range ? field.ranges : [];

  const min: number = first(fieldRanges)?.start ?? first(fieldRanges)?.end ?? 0;
  const max: number = last(fieldRanges)?.end ?? last(fieldRanges)?.start ?? 0;

  const fromFilter = first(
    condition?.filters.filter(
      (f) => (f as Filter).operator === ElasticsearchOperator.greaterThanOrEqual
    )
  );
  const from = fromFilter != null ? Number((fromFilter as Filter).data) : undefined;

  const toFilter = first(
    condition?.filters.filter((f) => (f as Filter).operator === ElasticsearchOperator.lessThan)
  );
  const to = toFilter != null ? Number((toFilter as Filter).data) : undefined;

  return {
    from: from ?? min,
    max,
    min,
    to: to ?? max,
  };
};

export const AggRangeBlock = ({ values = [], field, condition }: Props) => {
  const { upsertCondition } = useFilters();
  const [value, setValue] = useState(() => getInitialValues(field, condition));

  useEffect(() => {
    setValue(getInitialValues(field, condition));
  }, [field, condition]);

  const onChangeCommitted = useCallback(
    ([newFrom, newTo]: [number, number]) => {
      if (newFrom === value.min && newTo === value.to) {
        upsertCondition(field.fieldId);
      }

      const newFilters: Filter[] = [];
      if (newFrom !== value.min) {
        newFilters.push({
          fieldId: field.fieldId,
          operator: ElasticsearchOperator.greaterThanOrEqual,
          data: `${newFrom}`,
        });
      }
      if (newTo !== value.max) {
        newFilters.push({
          fieldId: field.fieldId,
          operator: ElasticsearchOperator.lessThan,
          data: `${newTo}`,
        });
      }

      upsertCondition(field.fieldId, {
        condition: ConditionType.and,
        filters: newFilters,
      });
    },
    [field.fieldId, upsertCondition, value]
  );

  const onChange = useCallback(
    ([newFrom, newTo]: [number, number]) => setValue((v) => ({ ...v, from: newFrom, to: newTo })),
    []
  );

  const numFormatter = useMemo(() => {
    const formatOpts = {
      ...field.format,
      currency: field.format.style === 'currency' ? 'USD' : undefined,
      unit: field.format.unit === 'kilometers' ? 'kilometer' : field.format.unit,
    };
    return Intl.NumberFormat('en', formatOpts);
  }, [field]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>{numFormatter.format(value.from)}</div>
        <div>{numFormatter.format(value.to)}</div>
      </Box>
      <Box
        sx={{
          padding: '0 4px',
        }}
      >
        <Slider
          value={[value.from, value.to]}
          onChange={(_, newValue) => onChange(newValue as [number, number])}
          onChangeCommitted={(_, newValue) => onChangeCommitted(newValue as [number, number])}
          min={value.min}
          max={value.max}
          step={(field as RangeAggregation).step}
          valueLabelDisplay="off"
        />
      </Box>
    </div>
  );
};
