import { EsAggregationsDefinition } from '@bisondesk/core-sdk/lib/types/definitions';
import { AggregationResponse } from '@bisondesk/core-sdk/lib/types/search';
import { ReferenceData } from '@bisondesk/core-sdk/lib/types/utils';
import TuneIcon from '@mui/icons-material/Tune';
import { Avatar, Box, Button, Chip, styled, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { useVehicleAggs } from '../../data/vehicles';
import { colors, theme } from '../../layout/styles';
import { concatSx } from '../../utils/formatters';
import { SEARCH_DEBOUNCE } from '../filters/constants';
import { FiltersProvider, useFilters } from '../filters/FiltersProvider';
import { FiltersSidebar } from '../filters/FiltersSidebar';
import { UrlAwareVehiclesGallery } from './VehiclesGallery';
type Props = {
  initialData?: AggregationResponse<ReferenceData>;
  definitions: { aggs: EsAggregationsDefinition };
};

const floatingButtonStyles: SxProps = {
  fontSize: '1.1rem',
  letterSpacing: '1px',
  height: 'auto',
  borderRadius: '50px',
  backgroundColor: colors.primary.A500,
  boxShadow: theme.shadows[5],
  padding: '0.8rem 2rem',
  '& svg': {
    marginRight: '0.5rem',
  },
};

const showVehiclesStyles: SxProps = {
  '& .MuiSvgIcon-root': {
    backgroundColor: 'transparent',
  },
  '& .MuiAvatar-root': {
    backgroundColor: '#006620',
  },
};

const CustomChip = styled(Chip)({
  root: {
    fontSize: '1.1rem',
    fontWeight: 300,
    padding: '0.5rem 1rem',
    letterSpacing: 0,
    height: 'auto',
    '& $avatar': {
      backgroundColor: 'none',
    },
    '& $deleteIcon': {
      height: '38px',
      width: '38px',
    },
    '& .MuiAvatar-root': {
      fontSize: '1em',
      marginLeft: '0.2em',
    },
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '1em',
  },
  avatar: {},
});

export const VehiclesBrowsePage = (props: Props) => (
  <FiltersProvider>
    <Content {...props} />
  </FiltersProvider>
);

const Content = ({ initialData, definitions }: Props) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { fullText, conditions, clearConditions } = useFilters();
  const { t } = useTranslation('common');

  const filterCount = useMemo(() => {
    const count = Object.values(conditions).reduce(
      (acc, condition) => (acc += condition.filters.length),
      0
    );
    return count;
  }, [conditions]);

  const [debouncedFullText] = useDebounce(fullText, SEARCH_DEBOUNCE);
  const aggResponse = useVehicleAggs(
    { fullText: debouncedFullText, filters: (Object.values(conditions) as any) || [] },
    {
      keepPreviousData: true,
      placeholderData: initialData,
    }
  );
  const [filtersVisibility, setFiltersVisibility] = useState<boolean>(false);

  const onClearFilters = useCallback(() => {
    clearConditions();
    setFiltersVisibility(false);
  }, [clearConditions, setFiltersVisibility]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Box
        sx={{
          width: '250px',

          '& > h3': {
            lineHeight: '2rem',
          },

          [theme.breakpoints.down('md')]: {
            width: 0,
          },
        }}
      >
        <FiltersSidebar
          loading={aggResponse.isFetching}
          open={filtersVisibility}
          searchDefinition={definitions.aggs}
          aggs={aggResponse.data}
          onClose={() => {
            setFiltersVisibility(false);
          }}
        />
        <Box
          sx={{
            position: 'fixed',
            bottom: '2rem',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 101,
            display: 'grid',
            gap: '10px',
            maxWidth: '250px',
            width: '65%',
          }}
        >
          {isSmallScreen && !filtersVisibility && (
            <CustomChip
              avatar={<TuneIcon />}
              sx={concatSx(floatingButtonStyles, {
                '& .MuiChip-deleteIcon': {
                  fontSize: '1rem',
                },
                '& .MuiAvatar-root': {
                  backgroundColor: '#4B5762',
                  height: '2em',
                  width: '2em',
                },
                '& .MuiChip-avatar': {
                  backgroundColor: 'transparent',
                },
              })}
              deleteIcon={filterCount > 0 ? <Avatar>{filterCount}</Avatar> : <></>}
              color="primary"
              label={t('filters')}
              clickable
              onDelete={() => setFiltersVisibility(true)}
              onClick={() => setFiltersVisibility(true)}
            />
          )}
          {isSmallScreen && filtersVisibility && (
            <>
              {filterCount > 0 ? (
                <CustomChip
                  deleteIcon={<Avatar>{filterCount}</Avatar>}
                  label={t('buttons.clear-filters')}
                  color="primary"
                  clickable
                  onDelete={onClearFilters}
                  sx={concatSx(floatingButtonStyles, {
                    backgroundColor: '#637381 !important',
                    '& .MuiChip-deleteIcon': {
                      fontSize: '1rem',
                    },
                    '& .MuiAvatar-root': {
                      backgroundColor: '#4B5762',
                      height: '2em',
                      width: '2em',
                    },
                  })}
                  onClick={onClearFilters}
                />
              ) : null}
              <Button
                color="primary"
                variant="contained"
                sx={floatingButtonStyles}
                onClick={() => setFiltersVisibility(false)}
              >
                {t('buttons.show_vehicles')}
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '1rem',

          [theme.breakpoints.down('md')]: {
            marginLeft: 0,
          },
        }}
      >
        <Box>
          <UrlAwareVehiclesGallery />
        </Box>
      </Box>
    </Box>
  );
};
