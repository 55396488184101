import { PaginatedList } from '@bisondesk/commons-sdk/lib/types';
import { SearchRequest, SortOrder } from '@bisondesk/core-sdk/lib/types/search';
import { PublicSearchVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';
import { NumberParam, useQueryParam } from 'use-query-params';
import { useSearchVehicles } from '../../data/vehicles';
import { colors, theme } from '../../layout/styles';
import { SEARCH_DEBOUNCE } from '../filters/constants';
import { useFilters } from '../filters/FiltersProvider';
import { Loading } from '../Loading';
import { VehicleGalleryItem } from './VehicleGalleryItem';

const clearFiltersButtonStyles = {
  borderRadius: '50px',
  backgroundColor: colors.greys.A500,
  marginLeft: '1rem',
  padding: '0 0.5rem 0 0.7rem',
  fontSize: '0.8rem',
  fontWeight: 300,

  '& svg': {
    marginLeft: '0.3rem',
    width: '1rem',
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
};

const PAGE_SIZE = 18;
export const UrlAwareVehiclesGallery = () => {
  const { fullText, query, clearConditions } = useFilters();
  const [debouncedFullText] = useDebounce(fullText, SEARCH_DEBOUNCE);
  const [page = 0, setPage] = useQueryParam('page', NumberParam);
  const { t } = useTranslation('common');

  const searchRequest: SearchRequest = useMemo(
    () => ({
      fullText: debouncedFullText,
      query,
      sortBy: [
        {
          fieldId: 'vehicle.internal.createdAt',
          order: SortOrder.desc,
        },
      ],
      limit: PAGE_SIZE,
      offset: page * PAGE_SIZE,
    }),
    [query, debouncedFullText, page]
  );

  const { data, isLoading } = useSearchVehicles(searchRequest);

  const handlePrev = useCallback(() => {
    setPage((prev) => (prev || 0) - 1);
  }, [setPage]);

  const handleNext = useCallback(() => {
    setPage((prev) => (prev || 0) + 1);
  }, [setPage]);

  return (
    <>
      {!isLoading && (
        <Box
          sx={{
            marginBottom: '0.5rem',
            display: 'flex',
            alignItems: 'center',

            '& h2': {
              fontSize: '1.2rem',
              fontWeight: 300,
            },

            '& h2 span': {
              color: colors.primary.A500,
            },
          }}
        >
          <h2>
            <span>{data.totalCount}</span> {t('vehicle', { count: data.totalCount })}
          </h2>
          {query && (
            <Button
              color="primary"
              variant="contained"
              sx={clearFiltersButtonStyles}
              onClick={clearConditions}
            >
              {t('buttons.clear-filters')} <CloseIcon />
            </Button>
          )}
        </Box>
      )}
      <VehiclesGallery
        data={data}
        isLoading={isLoading}
        page={page}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </>
  );
};

export const VehiclesGallery = ({
  data,
  isLoading,
  page,
  onNext,
  onPrev,
}: {
  data: PaginatedList<PublicSearchVehicle> | undefined;
  isLoading: boolean;
  onNext: () => void;
  onPrev: () => void;
  page: number;
}) => {
  const { t } = useTranslation('common');
  const results = data?.results ?? [];
  const totalCount = data?.totalCount ?? 0;

  // pagination
  const hasNext = totalCount >= 0 ? page < Math.ceil(totalCount / PAGE_SIZE) - 1 : false;
  const hasPrevious = page > 0;
  const from = totalCount === 0 ? 0 : page * PAGE_SIZE + 1;
  const to =
    totalCount >= 0 ? Math.min(totalCount, (page + 1) * PAGE_SIZE) : (page + 1) * PAGE_SIZE;

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {results.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '32px',
            color: colors.greys.A500,
            fontSize: '16px',
            '& > svg': {
              color: colors.primary.A600,
              fontSize: '5rem',
              margin: '32px',
            },
          }}
        >
          <div>{t('no-results')}</div>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(238px, 1fr))',
            gridGap: '8px',
          }}
        >
          {results.map((record: PublicSearchVehicle) => (
            <VehicleGalleryItem record={record} key={record.vehicle.id} />
          ))}
        </Box>
      )}

      {totalCount > 0 && (
        <div>
          <Box
            sx={{
              display: 'grid',
              gridGap: '8px',
              justifyContent: 'center',
              gridTemplateColumns: 'auto auto',
              margin: '16px 0',
            }}
          >
            <Button variant="outlined" disabled={!hasPrevious} onClick={onPrev}>
              {t('buttons.previous')}
            </Button>
            <Button variant="outlined" disabled={!hasNext} onClick={onNext}>
              {t('buttons.next')}
            </Button>
          </Box>
          <Box sx={{ padding: '8px', textAlign: 'center' }}>
            {t('glossary:pagination-interval', { from, to, total: totalCount })}
          </Box>
        </div>
      )}
    </>
  );
};
