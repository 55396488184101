import { ComplexFilter, ConditionType } from '@bisondesk/core-sdk/lib/types/search';
import produce from 'immer';
import React, { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { JsonParam, StringParam, useQueryParam } from 'use-query-params';

type ContextValue = {
  conditions: { [fieldId: string]: ComplexFilter };
  deleteCondition: (fieldId: string) => void;
  clearConditions: () => void;
  fullText?: string;
  query?: ComplexFilter;
  setFullText: (newFullText?: string) => void;
  upsertCondition: (fieldId: string, filter?: ComplexFilter) => void;
};

const FiltersContext = createContext<ContextValue | undefined>(undefined);

export const FiltersProvider = (props: PropsWithChildren<{}>) => {
  const [fullText, setFullText] = useQueryParam('fullText', StringParam);
  const [conditions = {}, setConditions] = useQueryParam<{
    [fieldId: string]: ComplexFilter;
  }>('conditions', JsonParam);

  const deleteCondition = useCallback(
    (fieldId: string) => {
      setConditions(
        produce(conditions, (draftFs) => {
          delete draftFs[fieldId];
        })
      );
    },
    [conditions, setConditions]
  );

  const upsertCondition = useCallback(
    (fieldId: string, condition?: ComplexFilter) => {
      if (condition != null) {
        setConditions({ ...conditions, [fieldId]: condition });
      } else {
        deleteCondition(fieldId);
      }
    },
    [conditions, deleteCondition, setConditions]
  );

  const clearConditions = useCallback(() => {
    setConditions({});
  }, []);

  const value = useMemo(
    () => ({
      deleteCondition,
      conditions,
      fullText: fullText != null ? fullText : undefined,
      query:
        Object.keys(conditions).length === 0
          ? undefined
          : {
              condition: ConditionType.and,
              filters: Object.values(conditions),
            },
      setFullText,
      upsertCondition,
      clearConditions,
    }),
    [deleteCondition, fullText, conditions, upsertCondition, setFullText]
  );

  return <FiltersContext.Provider value={value} {...props} />;
};

export const useFilters = (): ContextValue => {
  const value = useContext(FiltersContext);
  if (value === undefined) {
    throw new Error('useFilters must be used inside an FiltersProvider');
  }
  return value;
};
