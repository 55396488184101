import { EsAggregationsDefinition } from '@bisondesk/core-sdk/lib/types/definitions';
import { AggregationResponse } from '@bisondesk/core-sdk/lib/types/search';
import { ReferenceData } from '@bisondesk/core-sdk/lib/types/utils';
import { Box, Card, Drawer, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { navigate } from 'gatsby';
import { get, head } from 'lodash';
import React from 'react';
import { theme } from '../../layout/styles';
import { Loading } from '../Loading';
import { AggBlock } from './AggBlock';
import { StockNumberFinder } from './StockNumberFinder';

type Props = {
  aggs: AggregationResponse<ReferenceData>;
  searchDefinition: EsAggregationsDefinition;
  open: boolean;
  loading: boolean;
  onClose: () => void;
};

const siderStyles: SxProps = {
  flexShrink: 0,
  padding: '8px 16px',

  [theme.breakpoints.down('md')]: {
    paddingBottom: '10rem',
  },
};

export const FiltersSidebar = ({ aggs, open, onClose, searchDefinition, loading }: Props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (isSmallScreen) {
    return (
      <Drawer
        anchor="right"
        onClose={onClose}
        open={open}
        variant="temporary"
        sx={{
          zIndex: '100 !important',
          '& .MuiDrawer-paper': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflowX: 'hidden',
            '& > *': {
              flex: 1,
            },
          },
        }}
      >
        <Box sx={siderStyles}>
          {loading ? (
            <Loading />
          ) : (
            <Filters
              aggs={aggs}
              onClose={onClose}
              searchDefinition={searchDefinition}
              style={{ width: 'auto' }}
            />
          )}
        </Box>
      </Drawer>
    );
  }

  return (
    <Box
      sx={{
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }}
    >
      <Card sx={siderStyles}>
        {loading ? (
          <Loading />
        ) : (
          <Filters
            aggs={aggs}
            onClose={onClose}
            searchDefinition={searchDefinition}
            style={{ width: 'auto' }}
          />
        )}
      </Card>
      <StockNumberFinder
        onResults={(results) => {
          const first = head(results);
          if (first) {
            navigate(`/vehicles/${first.vehicle.external.identification.slug}`);
          }
        }}
      />
    </Box>
  );
};

const Filters = ({
  aggs,
  searchDefinition,
}: {
  aggs: AggregationResponse<ReferenceData>;
  searchDefinition: EsAggregationsDefinition;
  onClose: () => void;
  style?: any;
}) => (
  <div style={{ marginTop: '16px' }}>
    {searchDefinition.map((field) => (
      <AggBlock
        definition={searchDefinition}
        meta={aggs.meta}
        key={`agg-${field.fieldId}`}
        field={field}
        values={get(aggs.results, field.fieldId)}
      />
    ))}
  </div>
);
