import { EsAggregationsDefinition } from '@bisondesk/core-sdk/lib/types/definitions';
import { AggregationResultSet } from '@bisondesk/core-sdk/lib/types/search';
import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import { VehiclesBrowsePage } from '../components/vehicles/VehiclesBrowsePage';
import Layout from '../layout/Layout';

type Data = {
  vehicleAggs: { data: string };
  vehicleDefinitions: { data: string };
};

export default ({ data, location }: { data: Data; location: Location }) => {
  const definitions: { aggs: EsAggregationsDefinition } = useMemo(
    () => JSON.parse(data.vehicleDefinitions.data),
    [data.vehicleDefinitions]
  );
  const initialData: AggregationResultSet = useMemo(
    () => JSON.parse(data.vehicleAggs.data),
    [data.vehicleAggs]
  );

  return (
    <Layout location={location}>
      <VehiclesBrowsePage definitions={definitions} initialData={initialData} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    vehicleAggs {
      data
    }
    vehicleDefinitions {
      data
    }
  }
`;
