import { PublicSearchVehicle } from '@bisondesk/core-sdk/lib/types/vehicles';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchVehiclesByStockNumber } from '../../data/vehicles';

const referenceSearchStyles: SxProps = {
  marginTop: '1rem',
  padding: '0.5rem 0.5rem 0.5rem 1rem',
  background: 'white',
  borderRadius: '4px',
  boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
  width: 'calc(100% - 1.5rem)',

  '& > *:before': {
    display: 'none',
  },

  '& input': {
    border: '0 !important',
    fontSize: '0.9rem',
  },
};
type Props = {
  onResults: (results: PublicSearchVehicle[]) => void;
};

export const StockNumberFinder = ({ onResults }: Props) => {
  const [referenceNumber, setReferenceNumber] = useState('');
  const [searching, setSearching] = useState(false);
  const { t } = useTranslation('common');

  const onSubmit = async (el: any) => {
    el.preventDefault();
    if (!referenceNumber) return;
    setSearching(true);
    const resp = await fetchVehiclesByStockNumber(referenceNumber);
    setSearching(false);
    onResults(resp);
  };

  return (
    <form onSubmit={onSubmit}>
      <TextField
        sx={referenceSearchStyles}
        placeholder={t('find-by-reference')}
        variant="standard"
        value={referenceNumber}
        disabled={searching}
        onChange={(evt) => {
          setReferenceNumber(evt.target.value.trim() as string);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="search" onClick={onSubmit} edge="end" size="large">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};
