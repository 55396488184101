import { EsAggregationsDefinition } from '@bisondesk/core-sdk/lib/types/definitions';
import {
  Aggregation,
  AggregationResult,
  AggregationType,
} from '@bisondesk/core-sdk/lib/types/search';
import { ReferenceData } from '@bisondesk/core-sdk/lib/types/utils';
import { Box } from '@mui/material';
import React from 'react';
import { MultiLang } from '../MultiLang';
import { AggRangeBlock } from './AggRangeBlock';
import { AggTermsBlock } from './AggTermsBlock';
import { useFilters } from './FiltersProvider';

type Props = {
  field: Aggregation;
  meta: ReferenceData;
  values?: AggregationResult[];
  definition: EsAggregationsDefinition;
};

export const AggBlock = ({ field, values, meta, definition }: Props) => {
  const { conditions } = useFilters();

  return (
    <Box sx={{ marginTop: '16px' }}>
      <Box
        sx={{
          fontWeight: 500,
          fontSize: '16px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <MultiLang values={field.titles} />
      </Box>
      {field.type === AggregationType.term && (
        <AggTermsBlock
          definition={definition}
          meta={meta}
          values={values}
          field={field}
          condition={conditions[field.fieldId]}
        />
      )}
      {field.type === AggregationType.range && (
        <AggRangeBlock values={values} field={field} condition={conditions[field.fieldId]} />
      )}
    </Box>
  );
};
